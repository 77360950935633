export const environment = {
  production: false,
  cryptoSecret: 'db202c86-951e-417f-adb7-7a5d7afedc79',
  apiUrl: 'https://mobileapi.uat.site360.io/api/v3',
  kioskAppurl: 'https://anglicare-kiosk.uat.site360.io/form/',
  blobUrl: 'https://vistrak.blob.core.windows.net/vistrak-prod/',
  DateFormat: 'YYYY-MM-dd',
  StaticAlert: '1,2,3,4',
  PopupAlert: '5,6',
  PopUpAlertType: ['Popup Warning', 'Popup Info'],
  SuccessResponseCode: [200, 201, 202, 203, 204, 205, 206, 207, 208, 226, 422],
  portal: 'mobile',
  AppVersion: '1.1.8',
  languageCodes: {
    '84': 'en',
    '85': 'ar',
    '86': 'zh-CN',
    '170': 'ko',
    '171': 'vi',
    '172': 'hi',
    '173': 'ne',
    '174': 'bn',
    '175': 'fr',
    '176': 'de',
    '177': 'it',
    '178': 'el',
    '179': 'zu',
    '180': 'es',
    '181': 'pl',
  },
};

export const ApiAuthentication = {
  Header: 'x-api-key',
  Key: '010cca84-09fc-4445-9a9b-dfa2c7c371b0',
};

export const Report = {
  Site: '03A24577-AAF5-4B89-AF85-E971CFA168BB',
  Hazard: '02C5F0B8-DC51-4EC1-9723-D794BADBF1C3',
};
